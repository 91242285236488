<script setup lang="ts">
const { locale, t } = useI18n()
const { isMobile } = useBreakpoint()

const modalMobileApp = defineModel<boolean>({ required: true })
</script>

<template>
  <BaseModalV2 v-model="modalMobileApp" class-bg-color="bg-beige" no-margin>
    <template #content>
      <div
        class="flex w-full items-center justify-center gap-10 lg:px-10 lg:pb-10"
      >
        <div
          v-if="!isMobile"
          class="flex h-[341px] items-center justify-center"
        >
          <BaseNuxtImg
            class="h-fit"
            sizes="163px"
            :alt="t('title')"
            :src="`production/assets/images/app-mobile/phone-full-${locale}.png`"
          />
        </div>
        <div class="flex h-full min-w-[310px] flex-col items-center gap-6">
          <div>
            <p class="font-monospace mb-0 text-center font-bold">
              {{ t('title') }}
            </p>
            <p class="mb-0 text-center text-md">
              {{ t('text[0]') }}
              <br />
              {{ t('text[1]') }}
            </p>
          </div>

          <div v-if="!isMobile" class="text-center">
            <BaseQrCodeApp class="mx-auto" />
            <p
              class="mb-0 mt-3 font-sansSerif text-xs font-normal leading-4 text-gray-500"
            >
              {{ t('scan') }}
            </p>
          </div>
          <div v-else>
            <TheAppStoreLinks
              :size-image="{ width: 112, height: 36 }"
              class="justify-center"
              source="client_account_popin"
            />
            <BaseNuxtImg
              class="mt-7"
              sizes="238px"
              :alt="t('title')"
              :src="`production/assets/images/app-mobile/phone-crop-${locale}.png`"
            />
          </div>
        </div>
      </div>
    </template>
  </BaseModalV2>
</template>

<i18n lang="yaml">
fr:
  title: Téléchargez l’application
  text:
    - et découvrez des propriétés d'exception,
    - réservables en quelques clics.
  scan: Scannez le QR code
en:
  title: Download the app
  text:
    - and discover exceptional properties,
    - bookable in just a few clicks.
  scan: Scan the QR code
</i18n>
